<template lang="pug">
  div.contact
    div.container.blog__wrap
      h1 {{ blog.title }}
      div.blog__main
        img(
          :src="blog.imgTitle || ogImage"
          :alt="blog.title"
          @error="replaceByDefault"
        ).blog__img
        div.blog__content(v-html="resultHtml")
        div(v-if="blog?.tags?.length").mt-3
          span.mr-2 {{ $t('tags') }}:
          //router-link() {{ $t('backToBlog') }}
          v-chip(v-for="tag in blog.tags" :to="{ name: 'BlogPage', query: { filter_tags: tag } }" :key="tag" class="blog__tags mr-2")
            | {{ tag }}
        div.blog__button-row
          router-link.blog__button.rotate__arrow(:to="{ name: 'BlogPage' }") {{ $t('backToBlog') }}
          span {{ blog.publishedAtStr }}
</template>

<script>
import api from '@/plugins/api.js'

export default {
  name: 'InfoBlog',
  metaInfo () {
    const metaData = { ...this.blog?.meta_data }
    delete metaData.title
    metaData['og:type'] = 'article'
    this.blog?.imgTitle && (metaData['og:image'] = this.blog.imgTitle)

    return {
      title: this.blog?.meta_data?.title,
      meta: this.$metaDefault(metaData).meta
    }
  },
  data () {
    return {
      ogImage: require('@/assets/images/ogImage.png'),
      blog: {},
      meta: {}
    }
  },
  computed: {
    resultHtml () {
      return this.setImage(this.blog)
    }
  },
  mounted () {
    this.setData()
  },
  methods: {
    generateSafeImg (file, id, slug) {
      return `src="${ file }" alt="${ slug }-img-${ id }" onerror="this.src='${ this.ogImage }'"`
    },
    setImage (blog) {
      let result = blog.content
      blog.files.forEach(item => {
        result = result.replace(`src="#${ item.id }"`, this.generateSafeImg(item.file, item.id, blog.slug))
      })
      return result
    },
    replaceByDefault (e) {
      e.target.src = this.ogImage
    },
    async setData () {
      const locale = this.$i18n.locale
      const language = locale.toUpperCase()

      const data = await api.get(`api/v1/articles/article/${ this.$route.params.id }/?language=${ language }`)
      if (data.code !== 200) {
        this.$router.push({ name: 'not-found' })
        return
      }
      // this.meta = data.meta
      const months = this.$t('months')
      let publishedAtStr = ''
      if (data.data.published_at) {
        const date = new Date(data.data.published_at)
        const year = date.getFullYear()
        const month = months[date.getMonth()]
        const day = date.getDate()
        publishedAtStr = `${ day } ${ month } ${ year }`
      }
      this.blog = {
        publishedAtStr,
        imgTitle: data.data.files.find(img => img.is_title)?.file,
        ...data.data
      }
    }
  }
}
</script>
